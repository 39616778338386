









































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import ModelTable from "@/components/ModelTable.vue";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
import FileColumns from "@/data/columns/Files";
import ActivitySessionsHistory from "@/components/ActivitySessionsHistory.vue";
import { Project } from "@/data/models/Projects";
import { Case } from "@/data/models/Cases";
import { File } from "@/data/models/Files";
import HouseholdMemberBasicDataForm from "@/components/forms/HouseholdMemberBasicDataForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    ModelTable,
    ActivitySessionsHistory,
    HouseholdMemberBasicDataForm,
    DynamicForm,
    Loader,
    EntitySelect
  }
})
export default class CaseManagement extends Vue {
  private tab = "details";
  private splitterModel = 30;
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<Case>;

  private showResults = false;
  private item: any = null;
  private columns: any[] = FileColumns;
  private submission: any = null;
  private answers: any;
  private attachments: any[] | null = null;
  private developmentPlans: any[] | null = null;

  @Watch("provider")
  async onProviderChanged() {
    if (this.provider) {
      this.item = await this.$service.providers.householdMembers.fetchItemAsync(
        this.id
      );
    }
  }
}
